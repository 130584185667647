<template>
  <div class="reference">
    <ResponsiveImage
      v-if="logoImg && logoImg.filename"
      :lazyload="true"
      class="reference__logo"
      :mobile-src="logoImgFilename"
      :desktop-src="logoImgFilename"
      :alt="logoImgAlt"
      mobile-display-size="'34vw'"
      desktop-display-size="'70vw'"
      @click.native="onClick"
    />
    <component
      :is="logotype"
      v-else
      class="reference__logo"
      :class="logotype"
      @click="onClick"
    />
  </div>
</template>
<script>
import LogoInStyle from '@/assets/logos/instyle-logo.svg'
import LogoShape from '@/assets/logos/shape-logo.svg'

export default {
  name: 'References',
  components: {
    LogoInStyle,
    LogoShape,
  },
  props: {
    logotype: {
      type: String,
      default: '',
    },
    logoImg: {
      type: Object,
      default: null,
    },
    quotes: {
      type: Array,
      required: true,
      default: () => [],
    },
    referenceIndex: {
      type: Number,
      required: true,
    },
    intersectionObserver: {
      validator: (prop) => {
        if (typeof prop === 'object') return 'success'
        return 'warning'
      },
      default: null,
    },
  },
  data() {
    return {
      isObserved: false,
    }
  },
  computed: {
    logoImgFilename() {
      return this.logoImg?.filename || ''
    },
    logoImgAlt() {
      return this.logoImg?.alt || ''
    },
  },
  watch: {
    intersectionObserver() {
      if (!this.isObserved) this.initIntersectionObservation()
    },
  },
  methods: {
    onClick() {
      this.$emit('select', {
        quotes: this.quotes,
        activeIndex: this.referenceIndex,
        offsetLeft: this.referenceIndex === 0 ? 0 : this.$el.offsetLeft,
      })
    },
    initIntersectionObservation() {
      if (this.intersectionObserver) {
        this.intersectionObserver.observe(this.$el)
        this.isObserved = true
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.reference {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  scroll-snap-align: center;
  padding: spacing('small');

  &:first-child {
    scroll-margin-left: spacing('xlarge');
  }

  &:last-child {
    scroll-margin-right: spacing('xlarge');
  }

  .reference__logo {
    fill: $black;
    opacity: 0.3;
    transition-property: opacity, fill;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1);

    &:active {
      filter: var(--selected-fill-color);
    }
  }

  &.is-safari {
    .reference__logo {
      transition-property: fill;
    }
  }
}
.reference.active {
  .reference__logo {
    filter: var(--selected-fill-color);
    opacity: 1;
  }
}

// Desktop
@media screen and (min-width: $tablet-landscape) {
  .reference {
    display: block;
    width: calc((100% - 20rem) / 4);
    flex-grow: 0;
    padding-left: 0;
    padding-right: 0;
    scroll-snap-align: start;

    &:first-child {
      scroll-margin-left: 10rem;
    }

    &:last-child {
      scroll-margin-right: 10rem;
    }

    .reference__logo {
      &:hover {
        filter: var(--selected-fill-color);
        cursor: pointer;
      }
    }
  }
}

// High res Desktop
@media screen and (min-width: $laptop) {
  .reference {
    width: calc((100% - 22.6rem) / 4);

    &:first-child {
      scroll-margin-left: 20rem;
    }

    &:last-child {
      scroll-margin-right: 20rem;
    }
  }
}
</style>
