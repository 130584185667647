<template>
  <div
    class="references"
    :class="{ [verticalMargin]: verticalMargin, 'is-safari': isSafari }"
    :style="[selectedFillColorCss, referenceAlignCss, referenceAlignTextCss]"
    @mousedown="clearAutoScroll()"
    @touchstart="clearAutoScroll()"
  >
    <Quote
      v-for="(quote, i) in currentQuotes"
      :key="`references__quote${_uid}${i}`"
      ref="referencesQoute"
      v-editable="quote"
      v-bind="quote"
      class="references__quote mp--small"
      :inherited-quote-font-size="referenceQuoteSize"
      :inherited-quote-color="referenceQuoteColor"
    />
    <div
      ref="referencesSlider"
      class="references__slider mp--xxxlarge dp--large"
    >
      <Reference
        v-for="(reference, i) in references"
        :key="`references__slider__reference${_uid}${i}`"
        ref="referencesSliderReference"
        v-editable="reference"
        class="references__slider__reference"
        :class="{ active: isActive(i), 'is-safari': isSafari }"
        :logo-img="reference.logoImg"
        :logotype="reference.logotype"
        :quotes="reference.quotes"
        :reference-index="i"
        :intersection-observer="intersectionObserver"
        @select="setSelected"
        @hook:mounted="initAutoScroll(i)"
      />
    </div>
  </div>
</template>
<script>
import Reference from './Reference'
import Quote from '~/components/quote/Quote'

export default {
  name: 'References',
  components: {
    Reference,
    Quote,
  },
  props: {
    references: {
      type: Array,
      default: () => [],
    },
    selectedFillColor: {
      type: String,
      default: 'blue',
    },
    referenceAlign: {
      type: String,
      default: '',
    },
    referenceQuoteSize: {
      type: String,
      default: '',
    },
    referenceQuoteColor: {
      type: String,
      default: '',
    },
    verticalMargin: {
      type: String,
      default: '',
    },
    autoScroll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      quotes: [],
      activeIndex: undefined,
      intersectionObserver: null,
      desktop: null,
      autoScrollIntervalId: null,
      autoScrollInterval: 6000,
      fade: {
        opacity: [1, 0],
        translateY: [0, 10],
        duration: 350,
        easing: 'cubicBezier(.77,0,.18,1)',
        direction: 'alternate',
      },
      fadeAnimeDuration: 350,
      isSafari: false,
      autoScrollActive: false,
    }
  },
  computed: {
    selectedFillColorCss() {
      return {
        '--selected-fill-color': `var(--${this.selectedFillColor}-filter)`,
      }
    },
    referenceAlignCss() {
      return { '--reference-align': this.referenceAlign }
    },
    referenceAlignTextCss() {
      let textAlign = ''
      if (this.referenceAlign === 'flex-start') textAlign = 'left'
      else if (this.referenceAlign === 'center') textAlign = 'center'
      else if (this.referenceAlign === 'flex-end') textAlign = 'right'
      return { '--reference-align-text': textAlign }
    },
    currentQuotes() {
      return this.quotes
    },
  },
  mounted() {
    this.initDesktopQuery()
    this.initSelectedItem()
    this.initIntersectionObserver()
    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) // https://stackoverflow.com/a/23522755 https://stackoverflow.com/questions/58712209/weird-filtergrayscale-bug-in-safari-ios
  },
  beforeDestroy() {
    clearInterval(this.autoScrollIntervalId)
  },
  methods: {
    setSelected({ quotes, activeIndex, offsetLeft = undefined }) {
      if (this.activeIndex !== activeIndex) {
        this.fadeAnime().play()

        setTimeout(() => {
          this.activeIndex = activeIndex
          this.quotes = quotes

          if (offsetLeft !== undefined) {
            if (!this.desktop) offsetLeft -= 120 // account for scroll margin on mobile

            this.$refs.referencesSlider.scroll({
              left: offsetLeft,
              behavior: 'smooth',
            })
          }
        }, this.fadeAnimeDuration - this.fadeAnimeDuration / 3)
      }
    },
    initSelectedItem() {
      if (this.references && this.references.length) {
        const selectedItem = this.references[0]

        this.setSelected({
          quotes: selectedItem.quotes,
          activeIndex: 0,
        })
      }
    },
    initIntersectionObserver() {
      this.intersectionObserver = new IntersectionObserver(
        this.intersectionHandler,
        { root: this.$refs.referencesSlider, threshold: 0.9 }
      )
    },
    intersectionHandler(entries) {
      if (!this.desktop) {
        entries.forEach((entry) => {
          if (
            !this.autoScrollActive &&
            entry.isIntersecting &&
            this.activeIndex !== undefined
          ) {
            const activeIndex = entry.target.__vue__._props.referenceIndex

            this.setSelected({
              quotes: this.references[activeIndex].quotes,
              activeIndex,
            })
          }
        })
      }
    },
    initDesktopQuery() {
      const desktopQuery = window.matchMedia('(min-width: 1024px)')
      this.desktop = desktopQuery.matches

      desktopQuery.addEventListener('change', (e) => {
        this.desktop = e.matches
      })
    },
    onClick() {
      this.clearAutoScroll()
    },
    scrollTo(index) {
      let scrollPos = this.$refs.referencesSliderReference[index].$el.offsetLeft
      if (index === 0) scrollPos = 0
      if (!this.desktop) scrollPos -= 120 // account for scroll margin on mobile

      this.$refs.referencesSlider.scrollTo({
        left: scrollPos,
        behavior: 'smooth',
      })
    },
    initAutoScroll(i = this.references?.length - 1) {
      // init when the last refernce is mounted
      if (
        this.autoScroll &&
        i === this.references.length - 1 &&
        this.autoScrollIntervalId === null
      ) {
        this.autoScrollActive = true

        this.autoScrollIntervalId = setInterval(() => {
          ++this.activeIndex

          if (this.activeIndex > this.references.length - 1)
            this.activeIndex = 0

          this.scrollTo(this.activeIndex)
          this.fadeAnime().play()

          setTimeout(() => {
            this.quotes = this.references[this.activeIndex].quotes
          }, this.fadeAnimeDuration / 2)
        }, this.autoScrollInterval)
      }
    },
    clearAutoScroll() {
      clearInterval(this.autoScrollIntervalId)
      this.autoScrollIntervalId = null
      this.autoScrollActive = false

      if (this.autoScroll) {
        setTimeout(() => {
          this.initAutoScroll()
        }, this.autoScrollInterval)
      }
    },
    isActive(refernceIndex) {
      return this.activeIndex === refernceIndex
    },
    qouteElm() {
      if (this.$refs.referencesQoute && this.$refs.referencesQoute[0])
        return this.$refs.referencesQoute[0].$el
      return undefined
    },
    fadeAnime() {
      return this.$anime({
        targets: this.qouteElm(),
        opacity: [1, 0],
        translateY: [0, 10],
        duration: this.fadeAnimeDuration,
        easing: 'cubicBezier(.77,0,.18,1)',
        direction: 'alternate',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.references {
  display: flex;
  flex-direction: column;

  .references__quote {
    order: 2;
  }

  .references__slider {
    @include hide-scrollbars;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0;
    scroll-padding: 0;

    order: 1;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;

    // ios center fix
    &.is-safari::after {
      content: '';
      display: block;
      padding-right: 10rem;
      height: 1px;
    }
  }

  ::v-deep {
    .quote {
      .quote__bg-wrapper__text {
        text-transform: none;
      }
    }
  }
}
// Desktop
@media screen and (min-width: $tablet-landscape) {
  .references {
    .references__quote {
      order: 1;
      padding-bottom: spacing('xlarge');
    }

    .references__slider {
      gap: spacing('xlarge');
      padding-left: 10rem;
      padding-right: 10rem;
      order: 2;

      &::after {
        content: unset;
      }
    }
  }
}

// High res Desktop
@media screen and (min-width: $laptop) {
  .references {
    .references__quote {
      padding-bottom: spacing('xlarge');
    }

    .references__slider {
      gap: spacing('xxlarge');
      padding-left: 20rem;
      padding-right: 20rem;
    }
  }
}

// Mobile only
@media screen and (max-width: $tablet-landscape) {
  .references {
    ::v-deep {
      .quote__bg-wrapper__text {
        @include h--tiny;
      }
    }
  }
}
</style>
