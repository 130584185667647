<template>
  <div
    class="quote"
    :class="[verticalMargin, `mp--${mobilePadding}`, `dp--${desktopPadding}`]"
    :style="[
      quoteColorCss,
      quoteAlignCss,
      quoteAlignTextCss,
      backgroundColorCss,
    ]"
  >
    <div class="quote__bg-wrapper">
      <p v-if="!!quoteEyebrow" class="quote__bg-wrapper__eyebrow">
        {{ quoteEyebrow }}
      </p>
      <mp-link
        v-if="quoteLink && quoteLink.cached_url"
        :to="$u(quoteLink)"
        class="quote__bg-wrapper__author is-link"
      >
        <blockquote
          class="quote__bg-wrapper__text"
          :cite="$u(quoteLink)"
          :class="fontSize"
        >
          &ldquo;{{ quoteText }}&rdquo;
        </blockquote>
      </mp-link>

      <blockquote v-else class="quote__bg-wrapper__text" :class="fontSize">
        &ldquo;{{ quoteText }}&rdquo;
      </blockquote>

      <cite class="quote__bg-wrapper__author">
        {{ quoteAuthor }}
      </cite>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Quote',
  props: {
    quoteEyebrow: {
      type: String,
      default: '',
    },
    quoteAuthor: {
      type: String,
      default: '',
    },
    quoteText: {
      type: String,
      default: '',
    },
    quoteFontSize: {
      type: String,
      default: '',
    },
    inheritedQuoteFontSize: {
      type: String,
      default: '',
    },
    quoteAlign: {
      type: String,
      default: '',
    },
    quoteLink: {
      type: Object,
      default: null,
    },
    quoteColor: {
      type: String,
      default: '',
    },
    inheritedQuoteColor: {
      type: String,
      default: '',
    },
    mobilePadding: {
      type: String,
      default: '',
    },
    desktopPadding: {
      type: String,
      default: '',
    },
    verticalMargin: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: 'transparent',
    },
  },
  computed: {
    quoteWithCitation() {
      return `${this.quoteText}`
    },
    quoteAuthorComputed() {
      return this.quoteAuthor
    },
    fontSize() {
      if (this.inheritedQuoteFontSize) return this.inheritedQuoteFontSize
      return this.quoteFontSize
    },
    quoteColorCss() {
      return { '--quote-color': `var(--${this.color})` }
    },
    color() {
      if (this.inheritedQuoteColor) return this.inheritedQuoteColor
      return this.quoteColor
    },
    quoteAlignCss() {
      return { '--quote-align': this.quoteAlign }
    },
    backgroundColorCss() {
      return { '--background-color': `var(--${this.backgroundColor})` }
    },
    quoteAlignTextCss() {
      let textAlign = ''
      if (this.quoteAlign === 'flex-start') textAlign = 'left'
      else if (this.quoteAlign === 'center') textAlign = 'center'
      else if (this.quoteAlign === 'flex-end') textAlign = 'right'
      return { '--quote-align-text': textAlign }
    },
  },
}
</script>
<style lang="scss" scoped>
.quote {
  position: relative;
  color: var(--quote-color);

  .quote__bg-wrapper {
    display: flex;
    flex-direction: column;
    align-items: var(--reference-align, var(--quote-align));
    padding: spacing('medium');
    background-color: var(--background-color);
  }

  .quote__bg-wrapper__eyebrow {
    @include caps;

    text-align: var(--reference-align-text, var(--quote-align-text));
  }

  .quote__bg-wrapper__text {
    text-transform: uppercase;
    text-align: var(--reference-align-text, var(--quote-align-text));
  }

  .quote__bg-wrapper__author {
    @include p--large;

    font-weight: 600;
    font-style: normal;
    text-align: var(--reference-align-text, var(--quote-align-text));
  }

  .quote__bg-wrapper__author.is-link {
    cursor: pointer;
  }
}

// desktop
@media screen and (min-width: $tablet-landscape) {
  .quote {
    .quote__bg-wrapper {
      padding: spacing('large');
    }

    .quote__bg-wrapper__text {
      max-width: 1000px;
      padding-bottom: 0;
    }
  }
}
</style>
